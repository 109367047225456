import React, { useState, useEffect } from 'react';
import CustomSidebar from '../Sidebar/Sidebar';
import { Spinner, Card, Button } from 'flowbite-react';
import {
  getFirestore,
  collection,
  getDocs,
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';

// A simple linear regression function
// Given data points of {x, y}, returns {slope, intercept}
function linearRegression(data) {
  const n = data.length;
  if (n < 2) return null;

  const sumX = data.reduce((acc, val) => acc + val.x, 0);
  const sumY = data.reduce((acc, val) => acc + val.y, 0);
  const sumXY = data.reduce((acc, val) => acc + val.x * val.y, 0);
  const sumX2 = data.reduce((acc, val) => acc + val.x * val.x, 0);

  const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
  const intercept = (sumY - slope * sumX) / n;

  return { slope, intercept };
}

// Predict next year's count based on linear regression
function predictNextYear(data) {
  if (data.length < 2) {
    // Not enough data for regression; fallback to last known value
    return data.length > 0 ? data[data.length - 1].y : 0;
  }
  const { slope, intercept } = linearRegression(data);
  const maxYear = Math.max(...data.map(d => d.x));
  const nextYear = maxYear + 1;
  const prediction = slope * nextYear + intercept;
  return Math.round(prediction);
}

const EnrollmentDashboard = () => {
  const db = getFirestore();
  
  const [metrics, setMetrics] = useState({
    applications: null,
    completedApplications: null,
    admits: null,
    campusVisits: null,
    fafsaSubmissions: null,
  });
  
  const [loading, setLoading] = useState(true);
  
  const metricCollections = [
    { key: 'applications', label: 'Applications' },
    { key: 'completedApplications', label: 'Completed Applications' },
    { key: 'admits', label: 'Admits' },
    { key: 'campusVisits', label: 'Campus Visits' },
    { key: 'fafsaSubmissions', label: 'FAFSA Submissions' },
  ];

  // Fetch historical data from each metric's subcollection
  const fetchMetrics = async () => {
    setLoading(true);
    try {
      const newMetrics = {};

      for (const m of metricCollections) {
        const colRef = collection(db, 'enrollmentData', m.key, m.key);
        const snapshot = await getDocs(colRef);
        const dataPoints = [];
        snapshot.forEach((doc) => {
          const { year, count } = doc.data();
          if (typeof year === 'number' && typeof count === 'number') {
            dataPoints.push({ x: year, y: count });
          }
        });
        dataPoints.sort((a, b) => a.x - b.x);

        // Current count is last year's count
        const currentCount = dataPoints.length > 0 ? dataPoints[dataPoints.length - 1].y : 0;
        const prediction = predictNextYear(dataPoints);

        newMetrics[m.key] = {
          label: m.label,
          dataPoints,
          currentCount,
          prediction
        };
      }

      setMetrics(newMetrics);
    } catch (error) {
      console.error('Error fetching enrollment metrics:', error);
      toast.error('Failed to fetch enrollment metrics.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMetrics();
  }, []);

  if (loading) {
    return (
      <div className="flex min-h-screen">
        <CustomSidebar />
        <div className="flex-1 p-4 ml-20 flex flex-col justify-center items-center">
          <Spinner aria-label="Loading metrics" />
          <p className="text-gray-500 mt-2">Loading enrollment data...</p>
        </div>
      </div>
    );
  }

  // Prepare data for charts - we'll show a combined chart with the current counts and predictions
  const chartData = metricCollections.map((m) => ({
    name: metrics[m.key].label,
    current: metrics[m.key].currentCount,
    predicted: metrics[m.key].prediction
  }));

  // Actionable steps:
  // If predicted < current for any metric, we offer interventions.
  const actionableSteps = [];
  for (const m of metricCollections) {
    const { currentCount, prediction, label } = metrics[m.key];
    if (prediction < currentCount) {
      actionableSteps.push(
        `- ${label}: Projected decline from ${currentCount} to ${prediction}. Consider:
          * Enhancing targeted outreach and marketing campaigns
          * Revisiting financial aid packages or scholarships
          * Improving student engagement strategies (e.g., campus events, virtual tours, personalized advising)`
      );
    }
  }

  // If no declines predicted, suggest maintaining or scaling successes
  if (actionableSteps.length === 0) {
    actionableSteps.push("All metrics are stable or growing. Maintain current strategies, consider investing in successful outreach efforts, and keep monitoring to stay ahead of demographic changes.");
  }

  return (
    <div className="flex min-h-screen">
      <CustomSidebar />
      <div className="flex-1 p-4 ml-20 flex flex-col">
        <div className="text-center mb-4">
          <h1 className="text-3xl font-bold mb-2 mt-3">Enrollment Health Dashboard</h1>
          <p className="text-base text-gray-600 mb-2">
            This tool continuously monitors the health of your enrollment pipeline, predicts future trends, and provides actionable steps to mitigate the enrollment cliff. By focusing on key metrics—applications, completed applications, admits, campus visits, and FAFSA completions—and layering predictive analytics on top, you can strategically allocate resources and keep enrollments robust.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-8">
          {metricCollections.map((m) => {
            const { currentCount, prediction, label } = metrics[m.key];
            return (
              <Card key={m.key} className="flex flex-col justify-center items-center text-center">
                <h2 className="text-xl font-semibold">{label}</h2>
                <p className="text-lg">Current: {currentCount}</p>
                <p className="text-lg">Predicted: {prediction}</p>
              </Card>
            );
          })}
        </div>

        <div className="bg-white p-4 rounded-lg shadow mb-6">
          <h2 className="text-2xl font-semibold mb-4">Visual Representation</h2>
          <p className="text-gray-700 mb-2">The chart below compares current enrollment metrics with predicted next-year values to highlight potential shortfalls and opportunities.</p>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={chartData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="current" fill="#8884d8" name="Current" />
              <Bar dataKey="predicted" fill="#82ca9d" name="Predicted" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white p-4 rounded-lg shadow mb-6">
          <h2 className="text-2xl font-semibold mb-4">Actionable Insights</h2>
          <p className="text-gray-700 mb-2">
            Based on the predicted trends, consider the following steps:
          </p>
          <ul className="list-disc list-inside text-gray-700 whitespace-pre-line">
            {actionableSteps.map((step, i) => (
              <li key={i}>{step}</li>
            ))}
          </ul>
        </div>

        <div className="flex justify-center mt-4">
          <Button onClick={fetchMetrics} color="gray">Refresh Data</Button>
        </div>
      </div>
    </div>
  );
};

export default EnrollmentDashboard;
