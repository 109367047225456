import React, { useState, useEffect } from 'react';
import NavBar from '../NavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faMapMarkerAlt, faBook, faTag } from '@fortawesome/free-solid-svg-icons';
import '../../index.css';
import { doc, getDocs, getDoc, collection, query, where, orderBy, limit, onSnapshot } from "firebase/firestore";
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import ProfileJoyrideTour from './ProfileJoyrideTour';
import { Card, Button, Spinner } from 'flowbite-react';
import StudentAdvisorMeeting from './StudentAdvisorMeeting'; // Import the new component
import { toast } from 'react-toastify';
import TagList from './TagList';


const StudentProfile = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [schedule, setSchedule] = useState([]);
    const [photoURL, setPhotoURL] = useState([]);
    const [loading, setLoading] = useState(true);
    const { currentUser: authUser, fetchedCourseData, fetchedMajors, fetchedSurveyData } = useAuth();
    const [finalizedSchedule, setFinalizedSchedule] = useState({});
    const [completedSemesters, setCompletedSemesters] = useState({});
    const [milestoneData, setMilestoneData] = useState(null);
    const [milestones, setMilestones] = useState([]);
    const [completedMilestones, setCompletedMilestones] = useState(0);
    const [clickCount, setClickCount] = useState(0);
    const [selectedMajor, setSelectedMajor] = useState(authUser.surveyAnswers.Majors[0]); // New state for selected major
    const [dropdownVisible, setDropdownVisible] = useState(false); // State for dropdown visibility
    const [surveyAnswers, setSurveyAnswers] = useState({});
    const [advisors, setAdvisors] = useState([]); // Array to store multiple advisors
    const [selectedAdvisor, setSelectedAdvisor] = useState(null); // Advisor selected for scheduling    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [scheduledMeetings, setScheduledMeetings] = useState([]);
    const [recommendedResources, setRecommendedResources] = useState([]); // New state for recommended resources
    const [resourcesLoading, setResourcesLoading] = useState(true); // Loading state for resources
    const [resourcesError, setResourcesError] = useState(null); // Error state for resources

    const yearMapping = {
        "First Year": "year1",
        "Second Year": "year2",
        "Third Year": "year3",
        "Fourth Year": "year4",
        "Fifth Year": "year5"
    };

    useEffect(() => {
        if (authUser?.surveyAnswers?.Majors?.length > 0) {
            setSelectedMajor(authUser.surveyAnswers.Majors[0]); // Set initial major
        }
    }, [authUser]);

    const handleMajorChange = (major) => {
        setSelectedMajor(major);
        setDropdownVisible(false); // Hide dropdown after selection
    };

    const handleSelectMajorButtonClick = () => {
        setDropdownVisible(!dropdownVisible); // Toggle dropdown visibility
    };

    function findRequirementCourses(majorCourses, courses, nonUsableCourses) {
        // Create a set for faster lookup of non-usable courses
        const nonUsableSet = new Set(nonUsableCourses);
    
        // Filter out the requirements from majorCourses
        const requirements = majorCourses.filter(majorCourse =>
            (majorCourse.courseType === 'Major Course' && majorCourse.Requirements) ||
            majorCourse.courseType === 'Requirement'
        );
    
        // Map student's courses for quick access and exclude non-usable courses
        const studentCoursesMap = new Map();
        courses.forEach((course, index) => {
            if (!nonUsableSet.has(course.courseInfo_courseNumber)) {
                studentCoursesMap.set(course.courseInfo_courseNumber, { ...course, index });
            }
        });
    
        // Build the possible matches between requirements and student's courses
        const reqIndexToPossibleCourses = [];
        requirements.forEach((requirement, reqIndex) => {
            const possibleCourses = requirement.Possibilities.split(',').map(course => course.trim());
            reqIndexToPossibleCourses[reqIndex] = [];
            possibleCourses.forEach(courseNumber => {
                if (studentCoursesMap.has(courseNumber)) {
                    reqIndexToPossibleCourses[reqIndex].push(studentCoursesMap.get(courseNumber).index);
                }
            });
        });
    
        // Initialize match arrays
        const matchToCourse = new Array(requirements.length).fill(-1); // requirement index -> course index
        const matchToRequirement = new Array(courses.length).fill(-1); // course index -> requirement index
    
        // Helper function for DFS in bipartite matching
        function bpm(u, seen) {
            for (const v of reqIndexToPossibleCourses[u]) {
                if (!seen[v]) {
                    seen[v] = true;
                    if (matchToRequirement[v] === -1 || bpm(matchToRequirement[v], seen)) {
                        matchToRequirement[v] = u;
                        matchToCourse[u] = v;
                        return true;
                    }
                }
            }
            return false;
        }
    
        // Perform the matching
        for (let u = 0; u < requirements.length; u++) {
            const seen = new Array(courses.length).fill(false);
            bpm(u, seen);
        }
    
        // Extract the matched courses
        const largestValidArray = [];
        for (let u = 0; u < matchToCourse.length; u++) {
            if (matchToCourse[u] !== -1) {
                largestValidArray.push(courses[matchToCourse[u]].courseInfo_courseNumber);
            }
        }
    
        return largestValidArray;
    }

    function findElectiveCourses(majorCourses, courses) {
        // Filter out the elective requirements from majorCourses
        const electiveRequirements = majorCourses.filter(
            majorCourse => majorCourse.courseType === 'Elective'
        );
    
        // Build the possible matches between electives and student's courses
        const electiveIndexToPossibleCourses = [];
        electiveRequirements.forEach((electiveReq, electiveIndex) => {
            const electiveName = electiveReq.Possibilities;
            electiveIndexToPossibleCourses[electiveIndex] = [];
    
            // If the elective name is "Free-NonMajor-Elective", allow any course to fulfill it
            if (electiveName === "Free-NonMajor-Elective") {
                for (let i = 0; i < courses.length; i++) {
                    electiveIndexToPossibleCourses[electiveIndex].push(i);
                }
            } else {
                // Otherwise, only include courses that match specific elective fulfillment criteria
                courses.forEach((studentCourse, courseIndex) => {
                    if (
                        studentCourse.Elective_fulfillment &&
                        studentCourse.Elective_fulfillment.includes(electiveName)
                    ) {
                        electiveIndexToPossibleCourses[electiveIndex].push(courseIndex);
                    }
                });
            }
        });
    
        // Initialize match arrays
        const matchToCourse = new Array(electiveRequirements.length).fill(-1); // elective index -> course index
        const matchToElective = new Array(courses.length).fill(-1); // course index -> elective index
    
        // Helper function for DFS in bipartite matching
        function bpm(u, seen) {
            for (const v of electiveIndexToPossibleCourses[u]) {
                if (!seen[v]) {
                    seen[v] = true;
                    if (matchToElective[v] === -1 || bpm(matchToElective[v], seen)) {
                        matchToElective[v] = u;
                        matchToCourse[u] = v;
                        return true;
                    }
                }
            }
            return false;
        }
    
        // Perform the matching
        for (let u = 0; u < electiveRequirements.length; u++) {
            const seen = new Array(courses.length).fill(false);
            bpm(u, seen);
        }
    
        // Extract the matched courses
        const largestValidArray = [];
        for (let u = 0; u < matchToCourse.length; u++) {
            if (matchToCourse[u] !== -1) {
                largestValidArray.push(courses[matchToCourse[u]].courseInfo_courseNumber);
            }
        }
    
        return largestValidArray;
    }
    


    const getStudentInfo = async () => {
        try {
            if (authUser) {
                setCurrentUser(authUser);
                const userDocRef = doc(db, 'users', authUser.uid);
                const userDoc = await getDoc(userDocRef);
    
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    console.log('userData:', userData); // Debugging
    
                    // Validate and retrieve primaryTab
                    const primaryTab = userData?.primaryTab;
                    console.log('primaryTab:', primaryTab); // Debugging
    
                    if (!primaryTab) {
                        console.error('primaryTab is undefined');
                        // Optionally, you can set default values or handle this case as needed
                        return;
                    }
    
                    // Validate and retrieve schedules
                    const schedules = userData?.schedules;
                    if (!schedules || !schedules[primaryTab]) {
                        console.error(`No schedules found for primaryTab: ${primaryTab}`);
                        // Optionally, set default schedule or handle the absence of schedules
                        return;
                    }
    
                    const retrievedFinalizedSchedule = schedules[primaryTab];
                    setFinalizedSchedule(retrievedFinalizedSchedule);
                    console.log('finalizedSchedule:', finalizedSchedule); // Debugging
    
                    // Retrieve completedSemesters and photoURL with defaults
                    const retrievedCompletedSemesters = userData.completedSemesters || {};
                    setCompletedSemesters(retrievedCompletedSemesters);

                    const photoUrl = userData.photoUrl || '/default.jpg';
                    setPhotoURL(photoUrl);

                    let courseArray = [];
                    for (let i = 1; i <= 5; i++) { // Adjusted to handle up to fifth year if needed
                        let year = `year${i}`;
                        for (let j = 1; j <= 2; j++) {
                            let semester = `S${j}`;
                            if (finalizedSchedule[year] && finalizedSchedule[year][semester]) {
                                courseArray.push(...finalizedSchedule[year][semester]['courses']);
                            }
                        }
                    }

                    const studentCourses = fetchedCourseData
                        ? fetchedCourseData.filter(course => courseArray.includes(course.courseInfo_courseNumber))
                        : [];

                    const retrievedSurveyAnswers = userData?.surveyAnswers;
                    setSurveyAnswers(retrievedSurveyAnswers);

                    // Retrieve the academic year from surveyAnswers with a default
                    const seeYr = userData.surveyAnswers?.Year || 'defaultYear';
                    const mappedSeeYr = yearMapping[seeYr];
                    if (!mappedSeeYr) {
                        console.warn(`Year mapping for '${seeYr}' not found.`);
                    }
    
                    // Aggregate courses for the specific academic year
                    let courseArray1 = [];
                    for (let j = 1; j <= 2; j++) {
                        let semester = `S${j}`;
                        if (retrievedFinalizedSchedule[mappedSeeYr] && retrievedFinalizedSchedule[mappedSeeYr][semester]) {
                            courseArray1.push(...retrievedFinalizedSchedule[mappedSeeYr][semester]['courses']);
                        }
                    }

                    const year = userData.surveyAnswers?.Year || 'N/A';

                    await fetchScheduleData(year, schedules[primaryTab]);

                    console.log("Student info fetched successfully");
                } else {
                    console.error('User document does not exist');
                }
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoading(false);
        }
    };    



    const calculateProgressForMajor = (major) => {
        if (!major || !fetchedMajors){
            return {
                takenCoursesCount: 0,  // Total taken courses count
                planCoursesCount: 0,     // Remaining planned courses count
                totCoursesCount: 0           // Total courses count for the major
            }
        }

        // Aggregate courses from all years and semesters
        let courseArray = [];
        for (let i = 1; i <= 5; i++) { // Adjusted to handle up to fifth year if needed
            let year = `year${i}`;
            for (let j = 1; j <= 2; j++) {
                let semester = `S${j}`;
                if (finalizedSchedule[year] && finalizedSchedule[year][semester]) {
                    courseArray.push(...finalizedSchedule[year][semester]['courses']);
                }
            }
        }
    
        // Get the student courses
        const studentCourses = fetchedCourseData
            ? fetchedCourseData.filter(course => courseArray.includes(course.courseInfo_courseNumber))
            : [];

        
        // Get the major courses and total courses count
        const majorCourses = Object.values(fetchedMajors[major]);
        const totalCourses = majorCourses.filter(majorCourse => majorCourse.courseType !== 'Milestone').length;
    
        // Taken Courses Logic
        const takenCoursesSet = new Set();
        Object.keys(completedSemesters).forEach(year => {
            Object.keys(completedSemesters[year]).forEach(semesterKey => {
                if (completedSemesters[year][semesterKey]) {
                    const completedCourses = finalizedSchedule[year]?.[semesterKey]?.courses || [];
                    completedCourses.forEach(course => {
                        takenCoursesSet.add(course);
                    });
                }
            });
        });
        const takenCoursesArray = Array.from(takenCoursesSet);
        const takenCourses = fetchedCourseData.filter(course => takenCoursesArray.includes(course.courseInfo_courseNumber));
    
        // Identify taken major courses
        // const majorRequiredSpecificCourses = majorCourses.filter((majorCourse) => ((majorCourse.courseType == 'Major Course') && (majorCourse.Possibilities == "")));
        const majorRequiredSpecificCourses = majorCourses.filter((majorCourse) => ((majorCourse.courseType == 'Major Course')));
        const takenMajorCoursesArray = takenCourses.filter(course => {
            return majorRequiredSpecificCourses.some(majorCourse => majorCourse.courseInfo_courseNumber === course.courseInfo_courseNumber);
        });
    
        // Identify taken elective courses
        const takenElectiveCoursesArray = findElectiveCourses(
            majorCourses.filter(course => course.courseType === 'Elective'),
            takenCourses
        );
    
        // Identify taken requirement courses
        const takenRequirementCoursesArray = findRequirementCourses(
            majorCourses.filter(course => (course.courseType === 'Major Course' && !course.Requirements) || course.courseType === 'Requirement'),
            takenCourses,
            takenElectiveCoursesArray
        );
    
        // Calculate all major, elective, and requirement courses
        const allMajorCoursesArray = studentCourses.filter(course => {
            return majorRequiredSpecificCourses.some(majorCourse => majorCourse.courseInfo_courseNumber === course.courseInfo_courseNumber);
        });
    
        const allElectiveCoursesArray = findElectiveCourses(
            majorCourses.filter(course => course.courseType === 'Elective'),
            studentCourses
        );
    
        const allRequirementCoursesArray = findRequirementCourses(
            majorCourses.filter(course => (course.courseType === 'Major Course' && !course.Requirements) || course.courseType === 'Requirement'),
            studentCourses,
            allElectiveCoursesArray
        );
    
        // Calculate counts
        let takenCoursesLengths = takenMajorCoursesArray.length + takenElectiveCoursesArray.length + takenRequirementCoursesArray.length;
        let allCoursesLengths = allMajorCoursesArray.length + allElectiveCoursesArray.length + allRequirementCoursesArray.length - takenCoursesLengths;
    
        return {
            takenCoursesCount: takenCoursesLengths,  // Total taken courses count
            planCoursesCount: allCoursesLengths,     // Remaining planned courses count
            totCoursesCount: totalCourses            // Total courses count for the major
        };
    };
    

    // Get progress based on the selected major
    const { takenCoursesCount, planCoursesCount, totCoursesCount}  = calculateProgressForMajor(selectedMajor);
    
    const fetchScheduleData = (year, finalizedSchedule) => {
        if (year && finalizedSchedule) {
            const mappedYear = yearMapping[year];
            if (mappedYear) {
                const fallCourses = [];
                const springCourses = [];
                // Check if Fall semester exists and push courses to fallCourses array
                if (finalizedSchedule[mappedYear]?.S1) {
                    fallCourses.push(...finalizedSchedule[mappedYear].S1.courses);
                }
                // Check if Spring semester exists and push courses to springCourses array
                if (finalizedSchedule[mappedYear]?.S2) {
                    springCourses.push(...finalizedSchedule[mappedYear].S2.courses);
                }
                // Set the organized schedule state
                setSchedule({
                    Fall: fallCourses,
                    Spring: springCourses,
                });
            }
        }
    };    

    useEffect(() => {
        const fetchMilestoneData = async () => {
            if (authUser) {
                const userDocRef = doc(db, 'users', authUser.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const data = userDoc.data();
                    setMilestoneData(data);
    
                    if (data.finalizedMilestones) {
                        const primaryTab = data.primaryTab || 'defaultTab'; // Get the active tab
                        const milestonesInprimaryTab = data.finalizedMilestones[primaryTab] || {};
    
                        const milestoneList = Object.values(milestonesInprimaryTab).reduce((acc, yearData) => {
                            Object.values(yearData).forEach(semData => {
                                if (semData.milestones) {
                                    acc = acc.concat(semData.milestones);
                                }
                            });
                            return acc;
                        }, []);
    
                        setMilestones(milestoneList);
                        const completed = milestoneList.filter(milestone => milestone.completed).length;
                        setCompletedMilestones(completed);
                    }
                }
            }
        };
        fetchMilestoneData();
    }, [authUser]);

    useEffect(() => {
        if (authUser && fetchedCourseData) {
            getStudentInfo();
        }
    }, [authUser, fetchedCourseData, fetchedSurveyData]);

    const handleNameClick = () => {
        setClickCount((prevCount) => {
            const newCount = prevCount + 1;
            if (newCount === 4) {
                navigate('/roles');
            }
            return newCount;
        });
    };

    useEffect(() => {
        const fetchAdvisors = async () => {
            try {
                if (!currentUser) {
                    setLoading(true);
                    return;
                }
                const studentId = currentUser.uid;

                // Fetch the advisor assignments from 'studentAdvisors' collection
                const mappingDoc = await db.collection('studentAdvisors').doc(studentId).get();

                if (!mappingDoc.exists) {
                    // toast.info('You have not been assigned any advisors yet.');
                    setIsLoading(false);
                    return;
                }

                const { advisorIds } = mappingDoc.data();

                if (!advisorIds || advisorIds.length === 0) {
                    // toast.info('You have not been assigned any advisors yet.');
                    setIsLoading(false);
                    return;
                }

                // Fetch details for each advisor
                const advisorPromises = advisorIds.map(id => db.collection('advisors').doc(id).get());
                const advisorDocs = await Promise.all(advisorPromises);


                // Filter out any non-existing advisor documents
                const existingAdvisors = advisorDocs
                    .filter(doc => doc.exists)
                    .map(doc => ({
                        id: doc.id,
                        advisorId: doc.id, // Assuming advisorId is same as user id
                        firstName: doc.data().firstName,
                        lastName: doc.data().lastName,
                        meetingLink: doc.data().meetingLink || '',
                        profilePicture: doc.data().profilePicture || '/default.jpg', // Assuming profilePicture field
                    }));

                const missingAdvisors = advisorDocs.filter(doc => !doc.exists).map(doc => doc.id);
                if (missingAdvisors.length > 0) {
                    toast.error(`Assigned advisor data not found for IDs: ${missingAdvisors.join(', ')}`);
                }

                setAdvisors(existingAdvisors);
            } catch (error) {
                console.error('Error fetching advisors:', error);
                toast.error('Failed to fetch advisors.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchAdvisors();
    }, [currentUser]);


    useEffect(() => {
        if (authUser) {
            // Define the query for scheduled meetings
            const meetingsQuery = query(
                collection(db, 'meetings'),
                where('studentId', '==', authUser.uid),
                where('status', '==', 'Scheduled'),
                orderBy('datetime', 'asc'),
                limit(5)
            );

            // Set up the onSnapshot listener
            const unsubscribe = onSnapshot(meetingsQuery, (snapshot) => {
                const meetingsData = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setScheduledMeetings(meetingsData);
            }, (error) => {
                console.error('Error fetching scheduled meetings:', error);
                toast.error('Failed to fetch scheduled meetings.');
            });

            // Cleanup listener on unmount
            return () => unsubscribe();
        }
    }, [authUser]);

    const fetchScheduledMeetings = async () => {
        try {
          const meetingsSnapshot = await db
            .collection('meetings')
            .where('studentId', '==', authUser.uid)
            .where('status', '==', 'Scheduled')
            .orderBy('datetime', 'asc')
            .limit(5)
            .get();
      
          const meetingsData = meetingsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));
      
          setScheduledMeetings(meetingsData);
        } catch (error) {
          console.error('Error fetching scheduled meetings:', error);
        }
      };

      useEffect(() => {
        if (authUser) {
            fetchScheduledMeetings();
        }
    }, [authUser]);
      
        // Fetch recommended resources
        const fetchRecommendedResources = async () => {
            try {
                if (currentUser) {
                    const userDocRef = doc(db, 'users', currentUser.uid);
                    const userDoc = await getDoc(userDocRef);
        
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        const resourceIds = userData.Resources || [];
        
                        if (resourceIds.length > 0) {
                            const resourcesData = [];
                            const batchSize = 10;
                            const batches = [];
        
                            // Split resourceIds into batches of 10
                            for (let i = 0; i < resourceIds.length; i += batchSize) {
                                const batchIds = resourceIds.slice(i, i + batchSize);
                                const resourcesCollection = collection(db, 'resources');
                                const resourcesQuery = query(resourcesCollection, where('__name__', 'in', batchIds));
                                batches.push(getDocs(resourcesQuery));
                            }
        
                            // Execute all batches concurrently
                            const snapshots = await Promise.all(batches);
        
                            // Aggregate results from all batches
                            snapshots.forEach(snapshot => {
                                snapshot.docs.forEach(docSnap => {
                                    resourcesData.push({
                                        id: docSnap.id,
                                        ...docSnap.data(),
                                    });
                                });
                            });
        
                            setRecommendedResources(resourcesData);
                        } else {
                            setRecommendedResources([]);
                        }
                    } else {
                        setRecommendedResources([]);
                    }
                }
            } catch (error) {
                console.error('Error fetching recommended resources:', error);
                setResourcesError('Failed to load recommended resources.');
            } finally {
                setResourcesLoading(false);
            }
        };
    
        useEffect(() => {
            if (currentUser) {
                fetchRecommendedResources();
            }
        }, [currentUser]);
        
        // Function to open the scheduling modal
    const openModal = () => {
        setIsModalOpen(true);
    };

      // Function to close the scheduling modal
    const closeModal = () => {
        setIsModalOpen(false);
    };
    
    const ScheduleMeetingModal = ({ isOpen, onClose, title, children }) => {
        // Close the modal when the Escape key is pressed
        useEffect(() => {
          const handleEsc = (event) => {
            if (event.key === 'Escape') onClose();
          };
          window.addEventListener('keydown', handleEsc);
          return () => window.removeEventListener('keydown', handleEsc);
        }, [onClose]);
    
        if (!isOpen) return null;
    
        return (
          <div
            className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50"
            onClick={onClose} // Close when clicking on the backdrop
          >
            <div
              className="relative w-full max-w-7xl h-full max-h-full"
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            >
              {/* Modal content */}
              <div className="relative bg-white rounded-lg shadow-lg dark:bg-gray-700 flex flex-col h-full">
                {/* Modal header */}
                <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
                  <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                    {title}
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={onClose}
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
    
                {/* Modal body */}
                <div className="p-6 space-y-6 overflow-y-auto flex-grow bg-white dark:bg-gray-700">
                  {children}
                </div>

              </div>
            </div>
          </div>
        );
      };

    if (loading) {
        return (
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        );
      }

    const totalMilestones = milestones.length;
    const milestoneProgress = totalMilestones > 0 ? Math.round((completedMilestones / totalMilestones) * 100) : 0;
    // const takenCourses = 0; // Since completedCourses is currently zero
    const progressTaken = totCoursesCount > 0 ? Math.round((planCoursesCount / totCoursesCount) * 100) : 0;
    const progressDone = totCoursesCount > 0 ? Math.round((takenCoursesCount / totCoursesCount) * 100) : 0;
    const remainingCourses = totCoursesCount - takenCoursesCount - planCoursesCount;

    const userFullName = () => {
        if (authUser && authUser.firstName && authUser.lastName) {
            return `${authUser.firstName} ${authUser.lastName}`;
        }
        return 'User';
    };


    const calculateTotalCredits = (schedule) => {
        const allClasses = Object.values(schedule).flat();

        const filteredCourses = fetchedCourseData.filter(course =>
            allClasses.includes(course.courseInfo_courseNumber)
        );

        const totalCredits = filteredCourses.reduce((sum, course) => {
            return sum + parseInt(course.Credits, 10);
        }, 0);

        return totalCredits;
    }


    return (
        <div>
        <NavBar />
        {/* <ProfileJoyrideTour/> */}
        <Card className="w-full max-w-6xl px-4 bg-white-100 shadow-md relative mt-20"> {/* Widen the card */}
            <div className="flex items-center mb-1">
                <img className="w-[160px] h-[160px] mb-4 mr-5 rounded-full" src={photoURL || '/default.jpg'} alt="Profile Picture" />
                <div className="flex-grow max-w-[calc(100%-100px)]">
                    <h1 className="text-2xl font-bold mb-1" onClick={handleNameClick}>{userFullName()}</h1>
                    {authUser?.email && <p className="text-sm mb-1">{authUser.email}</p>}
                    {(authUser?.surveyAnswers?.Year || 'N/A') && (authUser?.surveyAnswers?.Faculties?.length > 0 || 'N/A') && (
                        <p className="text-sm mb-1">
                            {authUser?.surveyAnswers?.Year || 'N/A'} - {authUser?.surveyAnswers?.Faculties?.join(', ') || 'N/A'}
                        </p>
                    )}
                    {authUser?.surveyAnswers?.Majors?.length > 0 && (
                        <p className="text-sm">
                            <FontAwesomeIcon icon={faBook} className="mr-1" /> 
                            {authUser?.surveyAnswers?.Majors?.length === 1 ? 'Major in' : 'Majors in'} {authUser?.surveyAnswers?.Majors?.join(', ')}
                        </p>
                    )}
                    {authUser?.surveyAnswers?.Minors?.length > 0 && (
                        <p className="text-sm mb-1">
                            <FontAwesomeIcon icon={faBook} className="mr-1" /> 
                            {authUser.surveyAnswers.Minors.length === 1 ? 'Minor in' : 'Minors in'} {authUser.surveyAnswers.Minors.join(', ')}
                        </p>
                    )}
                    {authUser?.surveyAnswers?.Campus && (
                        <p className="text-sm mb-1">
                            <FontAwesomeIcon icon={faMapMarkerAlt} /> {authUser.surveyAnswers.Campus}
                        </p>
                    )}
                    {(() => {
                        const startMonth = authUser?.surveyAnswers?.SchoolStart?.month;
                        const startYear = authUser?.surveyAnswers?.SchoolStart?.year;
                        const endMonth = authUser?.surveyAnswers?.SchoolEnd?.month;
                        const endYear = authUser?.surveyAnswers?.SchoolEnd?.year;

                        const startDate = startMonth && startYear ? `${startMonth} ${startYear}` : 'N/A';
                        const endDate = endMonth && endYear ? `${endMonth} ${endYear}` : 'N/A';

                        if (startDate !== 'N/A' || endDate !== 'N/A') {
                            return (
                                <p className="text-sm mb-1">
                                    <FontAwesomeIcon icon={faGraduationCap} /> {startDate} - {endDate}
                                </p>
                            );
                        }

                        return null;
                    })()}
                    {/* Flex container for Pronouns and GPA */}
                    {(authUser?.surveyAnswers?.Pronouns || (Array.isArray(authUser?.surveyAnswers?.Tags) && authUser.surveyAnswers.Tags.length > 0)) && (
                        <div className="flex items-center text-sm">
                            <FontAwesomeIcon icon={faTag} className="mr-1 mb-1" />
                            {authUser?.surveyAnswers?.Pronouns && (
                                <span className="mr-2 mb-1">Pronouns: {authUser.surveyAnswers.Pronouns}</span>
                            )}
                            {/*<FontAwesomeIcon icon={faTag} className="mr-1 mb-1" />
                            {authUser?.surveyAnswers?.GPA && (
                                <span className="mr-2 mb-1">GPA: {authUser.surveyAnswers.GPA}</span>
                            )}*/}
                            <TagList tags={authUser?.surveyAnswers?.Tags} />
                        </div>
                        )}
                    </div>
                </div>
                <div className="flex justify-between ">
                <div className="mb-0">
                    <div className="flex items-center mb-2">
                        {surveyAnswers.Majors?.length > 1 ? (
                            <>
                                <h4>Progress Towards</h4>
                                <div className="relative">
                                    {/* Button that shows the major name and toggles dropdown */}
                                    <button
                                        className="ml-1 px-2 py-1 bg-gray-200 rounded transition-colors hover:bg-gray-300"
                                        onClick={handleSelectMajorButtonClick}
                                    >
                                        <span>{selectedMajor || 'N/A'}</span>
                                    </button>
                                    <span className="ml-1">Pathway</span>

                                    {/* Dropdown that appears when button is clicked */}
                                    {dropdownVisible && (
                                        <div className="absolute left-0 mt-1 bg-white border border-gray-200 rounded shadow-lg z-10">
                                            <ul className="py-1">
                                                {surveyAnswers.Majors.map((major, index) => (
                                                    <li key={index}>
                                                        <button
                                                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                                            onClick={() => handleMajorChange(major)}
                                                        >
                                                            {major}
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : (
                            <h4>
                                Progress Towards {surveyAnswers.Majors?.[0] || 'N/A'} Pathway
                            </h4>
                        )}
                    </div>
                    <div className="relative w-full  max-w-[22vw] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                        <div
                            className="absolute bg-green-500 h-2.5"
                            style={{
                                width: `${progressDone}%`,
                                left: '0',
                                top: 0,
                                borderTopLeftRadius: '9999px',
                                borderBottomLeftRadius: '9999px',
                                borderTopRightRadius: progressTaken > 0 ? '0' : '9999px',
                                borderBottomRightRadius: progressTaken > 0 ? '0' : '9999px'
                            }}
                        ></div>
                        <div
                            className="absolute bg-yellow-300 h-2.5"
                            style={{
                                width: `${progressTaken}%`,
                                left: `${progressDone}%`,
                                top: 0,
                                borderTopRightRadius: '9999px',
                                borderBottomRightRadius: '9999px',
                                borderTopLeftRadius: progressDone > 0 ? '0' : '9999px',
                                borderBottomLeftRadius: progressDone > 0 ? '0' : '9999px'
                            }}
                        ></div>
                    </div>
                    <div className="flex justify-between mt-2 space-x-10 w-full  max-w-[22vw] items-center">
                        <div className="rounded bg-green-100 px-2 py-0.3">
                            <span className="text-green-600 font-semibold">{takenCoursesCount} Taken</span>
                        </div>
                        <div className="rounded bg-yellow-100 px-2 py-0.3 ml-3">
                            <span className="text-yellow-500 font-semibold">{planCoursesCount || 0} Planned</span>
                        </div>
                        <div className="rounded bg-gray-100 px-2 py-0.3 ml-3">
                            <span className="text-gray-600 font-semibold">{remainingCourses || 0} Unplanned</span>
                        </div>
                    </div>

                    </div>
                    <div className="milestone-progress">
                        <h4 className="mb-2">Milestone Progress</h4>
                        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                            <div className="bg-blue-500 h-2.5 rounded-full" style={{ width: `${milestoneProgress || 0}%` }}></div>
                        </div>
                        <div className="flex justify-between mt-2">
                            <div className="rounded bg-blue-100 px-2 py-0.3">
                                <span className="text-blue-600 font-semibold">{completedMilestones || 0} Completed</span>
                            </div>
                            <div className="rounded bg-gray-100 px-2 py-0.3 ml-3">
                                <span className="text-gray-500 font-semibold">{(totalMilestones || 0) - (completedMilestones || 0)} Remaining</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-2 mt-1"> {/* Updated to 4 columns */}
                    {/* School Portals Column */}
                    <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
                        <h2 className="text-gray-700 font-semibold mb-2">School Portals</h2>
                        <ul className="my-4 space-y-3">
                            <li>
                                <a 
                                    href={process.env.REACT_APP_GENERAL_PORTAL} 
                                    target="_blank" 
                                    className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-100 hover:bg-gray-200 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                                >
                                    <img 
                                        src={process.env.REACT_APP_GENERAL_PORTAL_PICTURE} 
                                        alt="General Profile" 
                                        className="h-10 w-10 object-contain object-center" 
                                    />
                                    <span className="flex-1 ms-3 whitespace-nowrap">General Portal</span>
                                </a>
                            </li>
                            <li>
                                <a 
                                    href={process.env.REACT_APP_ADVISOR_PORTAL} 
                                    target="_blank" 
                                    className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-100 hover:bg-gray-200 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                                >
                                    <img 
                                        src={'https://i.postimg.cc/nhZ7d9cf/advisorportalpic.png'} 
                                        alt="Advisor Profile" 
                                        className="h-10 w-10 object-cover object-center" 
                                    />
                                    <span className="flex-1 ms-3 whitespace-nowrap">Advisor Portal</span>
                                </a>
                            </li>
                            <li>
                                <a 
                                    href={process.env.REACT_APP_COURSE_SEARCH} 
                                    target="_blank" 
                                    className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-100 hover:bg-gray-200 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                                >
                                    <img 
                                        src={'https://i.postimg.cc/MG3QKs0z/classsearchpic.png'} 
                                        alt="Course Profile" 
                                        className="h-10 w-10 object-cover object-center" 
                                    />
                                    <span className="flex-1 ms-3 whitespace-nowrap">Course Search</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Combined Personality Traits, Skills, and Interests Column */}
                    <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
                        <h2 className="text-gray-700 font-semibold mb-2">Traits, Skills, and Interests</h2>
                        <div className="overflow-y-auto max-h-60">
                            <div className="flex flex-wrap">

                                {/* Personality Traits Section */}
                                <h3 className="text-sm font-semibold w-full text-gray-600 mb-1">Personality Traits</h3>
                                {Array.isArray(authUser?.surveyAnswers?.RecPersonality) && authUser.surveyAnswers.RecPersonality.length > 0 ? (
                                    authUser.surveyAnswers.RecPersonality.map((trait, index) => (
                                        <span key={index} className="bg-purple-100 text-purple-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-purple-400 border border-purple-400 mb-2">{trait}</span>
                                    ))
                                ) : (
                                    <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 border border-red-400 mb-2">No Personality Traits</span>
                                )}

                                {/* Skills Section */}
                                <h3 className="text-sm font-semibold w-full text-gray-600 mb-1">Skills</h3>
                                {Array.isArray(authUser?.surveyAnswers?.RecSkills) && authUser.surveyAnswers.RecSkills.length > 0 ? (
                                    authUser.surveyAnswers.RecSkills.map((skill, index) => (
                                        <span key={index} className="bg-indigo-100 text-indigo-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-indigo-400 border border-indigo-400 mb-2">{skill}</span>
                                    ))
                                ) : (
                                    <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 border border-red-400 mb-2">No Skills</span>
                                )}

                                {/* Interests Section */}
                                <h3 className="text-sm font-semibold w-full text-gray-600 mb-1">Interests</h3>
                                {Array.isArray(authUser?.surveyAnswers?.RecInterests) && authUser.surveyAnswers.RecInterests.length > 0 ? (
                                    authUser.surveyAnswers.RecInterests.map((interest, index) => (
                                        <span key={index} className="bg-pink-100 text-pink-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400 mb-2">{interest}</span>
                                    ))
                                ) : (
                                    <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 border border-red-400 mb-2">No Interests</span>
                                )}

                            </div>
                        </div>
                    </div>

                    {/* Advising Section */}
                    <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200 flex flex-col">
                        <h2 className="text-gray-700 font-semibold mb-2">Advising Team</h2>

                        {/* Advisor Avatars with Scrollable Container */}
                        <div className="flex items-center space-x-4 mb-2 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300">
                            {advisors.slice(0, 3).map((advisor, index) => (
                                <div key={index} className="flex flex-col items-center">
                                    <img
                                        className="w-12 h-12 rounded-full object-cover mb-1"
                                        src={advisor.profilePicture || '/default.jpg'}
                                        alt={`${advisor.firstName} ${advisor.lastName}`}
                                    />
                                    <span className="text-sm font-medium text-gray-800 dark:text-white">
                                        {`${advisor.firstName} ${advisor.lastName}`}
                                    </span>
                                </div>
                            ))}
                            {advisors.length > 3 && (
                                <span className="text-sm text-blue-600 dark:text-blue-400">
                                    +{advisors.length - 3} more
                                </span>
                            )}
                        </div>

                        {/* Combined Upcoming Meetings with Scroll */}
                        <div className="mb-2">
                            <h4 className="text-sm font-semibold text-gray-700 dark:text-gray-100 mb-1">
                                Upcoming Meetings
                            </h4>
                            {scheduledMeetings.length > 0 ? (
                                <ul className="space-y-1 max-h-24 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300">
                                    {scheduledMeetings.slice(0, 5).map((meeting, index) => (
                                        <li key={index} className="text-sm text-gray-600 dark:text-gray-300">
                                            {new Date(meeting.datetime.seconds * 1000).toLocaleString('en-US', {
                                                month: 'short',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true,
                                            })}{' '}
                                            with {meeting.advisorName}
                                        </li>
                                    ))}
                                    {scheduledMeetings.length > 5 && (
                                        <li className="text-sm text-blue-600 dark:text-blue-400">
                                            +{scheduledMeetings.length - 5} more...
                                        </li>
                                    )}
                                </ul>
                            ) : (
                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                    No upcoming meetings scheduled.
                                </p>
                            )}
                        </div>

                        {/* Schedule Meeting Button */}
                        <button
                            onClick={openModal}
                            className="w-full text-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-100 hover:bg-gray-200 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                        >
                            Schedule Meeting
                        </button>
                    </div>
                </div>

                <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
                    <h2 className="text-gray-700 font-semibold">
                        Current Schedule ({authUser?.surveyAnswers?.Year || 'N/A'}) - Total Credits: {calculateTotalCredits(schedule)}
                    </h2>
                    {Object.keys(schedule).length > 0 ? (
                        <div className="flex justify-between">
                            <div className="w-1/2 pr-1" style={{ maxWidth: 'calc(50%)' }}> {/* Reduced gap */}
                                <h3 className="text-lg text-gray-500 font-semibold mt-2">Fall Semester</h3>
                                <ul>
                                    {schedule.Fall.length > 0 ? (
                                        schedule.Fall.map((course, index) => (
                                            <li
                                                key={index}
                                                className="bg-blue-200 text-blue-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-blue-700 dark:text-red-400 border border-red-400 mb-2 inline-block"
                                            >
                                                {course}
                                            </li>
                                        ))
                                    ) : (
                                        <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-red-900 dark:text-red-300">
                                            No Planned Courses for Fall Semester
                                        </span>
                                    )}
                                </ul>
                            </div>

                            <div className="w-1/2 pl-1" style={{ maxWidth: 'calc(50%)' }}> {/* Reduced gap */}
                                <h3 className="text-lg text-gray-500 font-semibold mt-2">Spring Semester</h3>
                                <ul>
                                    {schedule.Spring.length > 0 ? (
                                        schedule.Spring.map((course, index) => (
                                            <li
                                                key={index}
                                                className="bg-blue-200 text-blue-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-blue-700 dark:text-red-400 border border-red-400 mb-2 inline-block"
                                            >
                                                {course}
                                            </li>
                                        ))
                                    ) : (
                                        <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-1 rounded dark:bg-red-900 dark:text-red-300">
                                            No Planned Courses for Spring Semester
                                        </span>
                                    )}
                                </ul>
                            </div>
                        </div>
                    ) : (
                        <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                            No Planned Courses for Current Year
                        </span>
                    )}
                </div>

                {/* Recommended Resources Section */}
                <div className="bg-gray-50 p-3 rounded-lg shadow-md border border-gray-200">
                    <h2 className="text-gray-700 font-semibold mb-4">Recommended Resources</h2>
                    {resourcesLoading ? (
                        <div className="flex justify-center items-center">
                            <Spinner aria-label="Loading recommended resources" />
                        </div>
                    ) : resourcesError ? (
                        <div className="text-red-500">{resourcesError}</div>
                    ) : recommendedResources.length > 0 ? (
                        <div className="flex flex-col space-y-4">
                            {recommendedResources.map(resource => (
                                <div
                                    key={resource.id}
                                    className="flex flex-col sm:flex-row bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                                >
                                    {/* Resource Image */}
                                    <img
                                        className="w-48 h-48 sm:w-56 sm:h-56 flex-shrink-0 rounded-t-lg sm:rounded-l-lg object-cover"
                                        src={resource.image || 'https://via.placeholder.com/150'}
                                        alt={resource.name}
                                    />
                                    {/* Resource Details */}
                                    <div className="p-4 flex flex-col justify-between w-full">
                                        <div>
                                            <h5 className="text-lg font-bold text-gray-900 dark:text-white">{resource.name}</h5>
                                            <p className="text-sm text-gray-700 dark:text-gray-400 mt-1">{resource.description}</p>
                                            <p className="text-xs text-gray-500 dark:text-gray-400 mt-2">Department: {resource.department}</p>
                                        </div>
                                        {/* Learn More Button or No Link Message */}
                                        {resource.link ? (
                                            <Button
                                                color="gray"
                                                size="xs"
                                                className="mt-2 self-start"
                                                onClick={() => window.open(resource.link, '_blank')}
                                            >
                                                Learn More
                                            </Button>
                                        ) : (
                                            <span className="text-xs text-gray-500 mt-2">No link available</span>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="text-gray-500">No recommended resources available.</div>
                    )}
                </div>
                
            </Card>
            {/* Schedule Meeting Modal */}
            <ScheduleMeetingModal
                isOpen={isModalOpen}
                onClose={closeModal}
                title="Schedule a Meeting"
            >
            <div className="flex flex-col space-y-1">
                {/* Pass the advisors to StudentAdvisorMeeting */}
                {advisors.length > 0 ? (
                    <StudentAdvisorMeeting 
                    advisors={advisors} 
                    onClose={closeModal} 
                    onMeetingScheduled={fetchScheduledMeetings}
                    />
                ) : (
                <p className="text-gray-500">You have no assigned advisors.</p>
                )}
            </div>
            </ScheduleMeetingModal>
        </div>
    );    
};

export default StudentProfile;
