import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../contexts/AuthContext';
import ReactDOM from 'react-dom';
import { FaCheck } from 'react-icons/fa';
import handleAutofillCourse from '../../../Autofill';

const Modal = ({ isVisible, onClose, children }) => {
  if (!isVisible) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 justify-center items-center">
      {/* Backdrop with Blur */}
      <div
        className="fixed inset-0 bg-opacity-30 backdrop-blur-sm"
        onClick={onClose}
      ></div>
      {/* Modal content with rounded corners and max height */}
      <div
        className="relative p-4 w-full max-w-3xl max-h-[80vh] z-50 overflow-y-auto rounded-lg"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>,
    document.body
  );
};

const fetchMajorData = (fetchedMajors, major, dataType) => {
  try {
    const majorData = {};

    const calculateMaxYears = (courses) =>
      Math.max(...courses.map((course) => course.courseYear));

    const semesterMap = {
      S1: 'Fall',
      S2: 'Spring',
    };

    if (fetchedMajors[major]) {
      const majorCoursesFromBackend = Object.values(fetchedMajors[major]);

      const courseMap = {};

      const maxMajorYears = calculateMaxYears(majorCoursesFromBackend);

      // Initialize year-based structure
      for (let i = 1; i <= maxMajorYears; i++) {
        courseMap[`year${i}`] = {
          Fall: [],
          Spring: [],
        };
      }

      // Filter and organize courses based on the dataType
      majorCoursesFromBackend.forEach((course) => {
        const isRequirement =
          course.courseType === 'Requirement' ||
          (course.courseType === 'Major Course' && course.Requirements !== '');
        const isElective = course.courseType === 'Elective';
        const isMajorCourse =
          course.courseType === 'Major Course' && course.Requirements === '';

        const shouldInclude =
          (dataType === 'Requirement' && isRequirement) ||
          (dataType === 'Elective' && isElective) ||
          (dataType === 'Major Course' && isMajorCourse);

        if (shouldInclude && course.courseYear <= maxMajorYears) {
          const semester = semesterMap[course.CourseSem] || course.CourseSem;
          courseMap[`year${course.courseYear}`][semester].push(course);
        }
      });

      majorData[major] = courseMap;

      console.log(`Fetched ${dataType} Data:`, majorData);
      return majorData;
    } else {
      console.error('Current user or major is not defined.');
      return null;
    }
  } catch (error) {
    console.error(`Error fetching ${dataType} courses:`, error);
    return null;
  }
};

const handleAutofillVariableCourses = async (
    selectedTerms,
    major,
    users,
    templateName,
    fetchedMajors,
    fetchedCourseData
  ) => {
    const fetchedMajorElectives = fetchMajorData(fetchedMajors, major, 'Elective');
    const fetchedMajorRequirements = fetchMajorData(fetchedMajors, major, 'Requirement');
    const fetchedMajorCourses = fetchMajorData(fetchedMajors, major, 'Major Course');
    const numYears = 4;
  
    // Show a persistent loading toast
    const toastId = toast.loading(`Adding ${major} Pathway to selected students...`);
  
    try {
      const autofillPromises = users.flatMap((userId) =>
        selectedTerms.map((term) => {
          // Calculate year and semester based on term number (1 to 8)
          const yearNumber = Math.ceil(term / 2); // Dividing term by 2 and rounding up gives the year
          const semester = term % 2 === 1 ? 'Fall' : 'Spring'; // Odd terms are Fall (S1), even terms are Spring (S2)
  
          if (true) {
            return handleAutofillCourse(
              fetchedCourseData,
              yearNumber,
              semester,
              userId,
              templateName,
              numYears,
              fetchedMajorElectives,
              fetchedMajorRequirements,
              fetchedMajorCourses
            );
          }
          return Promise.resolve();
        })
      );
  
      await Promise.all(autofillPromises);
  
      // Update the loading toast to a success message
      toast.update(toastId, {
        render: `Successfully added the ${major} Pathway to selected students.`,
        type: "success",
        isLoading: false,
        autoClose: 5000 // Close after 5 seconds
      });
    } catch (error) {
      // Update the loading toast to an error message
      toast.update(toastId, {
        render: 'Failed to autofill selected terms.',
        type: "error",
        isLoading: false,
        autoClose: 5000 // Close after 5 seconds
      });
      console.error('Error autofilling courses:', error);
    }
  };
  
  

const TemplateModal = ({
  isTemplateModalVisible,
  setIsTemplateModalVisible,
  selectedStudents,
}) => {
  const { fetchedCourseData, fetchedMajors } = useAuth();
  const [isSemesterSelectionVisible, setIsSemesterSelectionVisible] =
    useState(false);
  const [selectedMajor, setSelectedMajor] = useState(null);
  const [templateName, setTemplateName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredMajors, setFilteredMajors] = useState(fetchedMajors);
  const [selectedTerms, setSelectedTerms] = useState([]);

  useEffect(() => {
    if (fetchedMajors) {
      // Filter majors based on the search query (case insensitive)
      const filtered = Object.entries(fetchedMajors)
        .filter(([major]) =>
          major.toLowerCase().includes(searchQuery.toLowerCase())
        ) // Filter based on the search query
        .reduce((acc, [major, value]) => {
          acc[major] = value; // Keep the entire object structure
          return acc;
        }, {});
      setFilteredMajors(filtered); // Update the filtered list
    }
  }, [searchQuery, fetchedMajors]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); // Update search query on input change
  };

  const handleMajorSelection = (major) => {
    setSelectedMajor(major);
    setIsSemesterSelectionVisible(true);
  };

  const handleTemplateNameChange = (e) => {
    const value = e.target.value;
    setTemplateName(value);
  };

  const toggleTermSelection = (year) => {
    setSelectedTerms((prevSelectedTerms) =>
      prevSelectedTerms.includes(year)
        ? prevSelectedTerms.filter((y) => y !== year)
        : [...prevSelectedTerms, year]
    );
  };

  const renderModalContent = () => {
    const nMap = {
      1: `Template Term 1`,
      2: 'Template Term 2',
      3: 'Template Term 3',
      4: 'Template Term 4',
      5: 'Template Term 5',
      6: 'Template Term 6',
      7: 'Template Term 7',
      8: 'Template Term 8',
    };

    const yMap = {
      1: `Fall 2024`,
      2: 'Spring 2025',
      3: 'Fall 2025',
      4: 'Spring 2026',
      5: 'Fall 2026',
      6: 'Spring 2027',
      7: 'Fall 2027',
      8: 'Spring 2028',
    };

    if (!isSemesterSelectionVisible) {
      return (
        <div className="grid grid-cols-1 gap-3">
          {Object.keys(filteredMajors || {}).map((major) => (
            <div
              key={major}
              className="p-2.5 border rounded text-left cursor-pointer hover:bg-gray-100"
              onClick={() => handleMajorSelection(major)}
            >
              {major}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <>
          {/* Template Name Input */}
          <div className="mb-4">
            <input
              type="text"
              id="templateName"
              className={`mt-1 mb-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
              placeholder="Enter template name for students"
              value={templateName}
              onChange={handleTemplateNameChange}
            />
          </div>

          {/* Year Selection Buttons */}
          <div className="grid grid-cols-2 gap-4">
            {[...Array(8)].map((_, i) => {
              const term = i + 1;
              const isSelected = selectedTerms.includes(term);
              return (
                <button
                  key={i}
                  className={`relative py-2 px-3 border rounded text-gray-500 font-semibold hover:bg-green-100 transition-colors duration-75 ${
                    isSelected ? 'bg-green-200' : 'bg-gray-50'
                  }`}
                  onClick={() => {
                    if (templateName.trim() !== '') {
                      toggleTermSelection(term);
                    }
                  }}
                  disabled={!templateName.trim()}
                >
                  <div className="relative flex justify-between items-start">
                    <div>
                      <span className="text-lg font-bold">{nMap[term]}</span>
                      <div className="text-left mt-0">
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                          Major: {selectedMajor}
                        </span>
                      </div>
                      <span className="absolute bottom-0 right-0 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-800">
                        {yMap[term]}
                      </span>
                    </div>
                    <div className="flex items-start">
                      {isSelected && (
                        <div className="ml-2 w-4 h-4 bg-green-800 rounded-full flex items-center justify-center mt-1">
                          <FaCheck className="text-white" size={10} />
                        </div>
                      )}
                    </div>
                  </div>
                </button>
              );
            })}
          </div>

          {/* Add Button */}
          <div className="flex justify-end mt-3">
            <button
              onClick={() => {
                if (selectedTerms.length > 0 && templateName.trim() !== '') {
                  handleAutofillVariableCourses(
                    selectedTerms,
                    selectedMajor,
                    selectedStudents,
                    templateName,
                    fetchedMajors,
                    fetchedCourseData
                  );
                  setIsTemplateModalVisible(false);
                  setIsSemesterSelectionVisible(false);
                  setSelectedMajor(null);
                  setSelectedTerms([]);
                }
              }}
              className={`px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none ${
                selectedTerms.length === 0 || !templateName.trim()
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
              disabled={selectedTerms.length === 0 || !templateName.trim()}
            >
              Add Selected Years
            </button>
          </div>
        </>
      );
    }
  };

  return (
    <Modal
      isVisible={isTemplateModalVisible}
      onClose={() => {
        setIsTemplateModalVisible(false);
        setIsSemesterSelectionVisible(false);
      }}
    >
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div
          className={`flex flex-col bg-white rounded-md border border-gray-500 max-w-3xl w-full ${
            isSemesterSelectionVisible ? 'h-[81vh]' : 'h-[70vh]'
          }`}
        >
          <div className="flex flex-col p-3 md:p-5 border-b dark:border-gray-600">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold text-gray-500 dark:text-white">
                Select a Template
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => {
                  setIsTemplateModalVisible(false);
                  setIsSemesterSelectionVisible(false);
                }}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            {!isSemesterSelectionVisible && (
              <div className="mt-3">
                <input
                  type="text"
                  placeholder="Search templates..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            )}

            <div className="flex space-x-2 mt-3">
              {selectedMajor && (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                  Major: {selectedMajor}
                </span>
              )}
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                Students Selected: {selectedStudents.length}
              </span>
            </div>
          </div>

          <div className="p-4 md:p-5 flex-1 overflow-y-auto">
            {renderModalContent()}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TemplateModal;
